@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 12px;
  padding-bottom: 6px;
  border: none;

  @media (--viewportMedium) {
    padding: 3px 0 29px 12px;
    padding: 4px 0 4px 12px;
  }
}

.list {
  margin: 0;
}

.item {
  padding: 4px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 8px 0;
  }
  & label{
   & :nth-child(1){
    display: none;
   }
  }
 & input[type=checkbox] + label {
    /* display: block; */
    cursor: pointer;
    font-family: 'Arial'
  }
  
  
 & input[type=checkbox] {
    display: none;
  }
  
  
 & input[type=checkbox] + label:before {
    content: "\2714";
    border: 0.1em solid #707070;
    border-radius: 0.2em;
    display: inline-block;
    width: 16px;
    height: 16px;
    padding-left: 2px;
    padding-top: 0px;
    margin-right: 12px;
    vertical-align: bottom;
    color: transparent;
    transition: .2s;
  }
  
  
 & input[type=checkbox] + label:active:before {
    transform: scale(0);
  }
  
  
 & input[type=checkbox]:checked + label:before {
    background: #bf5c3a;
    border-color: #bf5c3a;
    color: white;
  }
  
  
 & input[type=checkbox]:disabled + label:before {
    transform: scale(1);
    border-color: #aaa;
  }
  
  
 & input[type=checkbox]:checked:disabled + label:before {
    transform: scale(1);
    background-color: #F7C28F;
    border-color: #F7C28F;
  }
  
}
